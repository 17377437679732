import React from "react";
import Heading from "../components/Heading";
import { teamMembers } from "../constants/businessLogic";
import TeamCard from "../components/TeamCard";

const Team = () => {
  return (
    <section className=" pb-10 pt-4 lg:px-10 max-w-[85rem] mx-auto">
      <Heading
        text="Our Amazing"
        highlightedText="Team"
        extendedClass="pb-9 lg:pb-12"
      />
      <div className="flex flex-col gap-4">
        {teamMembers?.map((member) => (
          <TeamCard key={member.name} {...member} />
        ))}
      </div>
    </section>
  );
};

export default Team;
