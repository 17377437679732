import { StarAlt } from "../assests/images";

const Collapse = ({ title, children }) => {
  return (
    <div className="rounded-2xl mb-4  bg-secondary shadow-custom-1 p-6">
      <img
        src={StarAlt}
        alt="star-alt"
        style={{ width: "3rem", height: "3rem" }}
      />
      <h3 className="text-xl font-bold pt-6 pb-2">{title}</h3>
      <div className="text-base">{children}</div>
    </div>
  );
};

export default Collapse;
