import React from "react";
import Heading from "../components/Heading";
import { ORANGE_BOUNDED_VARIANTS } from "../constants/enum";
import { Star, StarAlt } from "../assests/images";
import { scrollToView } from "../utils";

const Hero = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center relative px-8 overflow-hidden">
      <div className="relative ">
        <img
          src={Star}
          alt="star"
          className="hidden lg:block absolute animate-bounce -top-1/2 -left-[15%] "
        />
        <img
          src={StarAlt}
          alt="star-alt"
          className="hidden lg:block absolute animate-spin-slow -bottom-3/4 -right-[15%] "
        />
        <Heading
          text="Innovate"
          highlightedText="Digital"
          highlightVariant={ORANGE_BOUNDED_VARIANTS.FILLED_WITH_ICON}
          tailingText="Solutions"
          extendedClass="flex-col sm:!flex-row items-center sm:items-end justify-center flex-wrap gap-5 mb-6 flex-wrap !text-4xl sm:!text-5xl md:!text-7xl "
        />
        <p className="text-center max-w-2xl  md:max-w-3xl text-lg mx-auto">
          At Chaev, we believe that creating engaging, tech-driven brands sparks
          innovation and builds a brighter future. By blending technology with
          creativity, we focus on{" "}
        </p>
      </div>
      <div
        onClick={scrollToView("commitment")}
        class="scroll-down mouse effect2 flex flex-col absolute bottom-0"
      >
        <label className="cursor-pointer">Scroll</label> <span></span>
      </div>
    </div>
  );
};

export default Hero;
