import React from "react";
import Heading from "../components/Heading";
import { ourProcess } from "../constants/businessLogic";
import Collapse from "../components/Collapse";

const Process = () => {
  return (
    <section className="py-10 lg:px-10 max-w-[85rem] mx-auto">
      <Heading
        text="Our"
        highlightedText="Process"
        extendedClass="pb-9 lg:pb-12"
      />
      <div className="flex flex-col md:flex-row gap-4">
        {ourProcess?.map((process) => (
          <Collapse key={process.key} title={process.label}>
            {process.children}
          </Collapse>
        ))}
      </div>
    </section>
  );
};

export default Process;
