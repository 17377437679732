import React from "react";
import Heading from "../components/Heading";
import { servicesList } from "../constants/businessLogic";
import ServiceCard from "../components/ServiceCard";

const Service = () => {
  return (
    <section
      id="service"
      className="py-10 lg:px-10 max-w-[85rem] mx-auto scroll-m-[72px]"
    >
      <Heading
        text="Our"
        highlightedText="Services"
        extendedClass="pb-9 lg:pb-12"
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8">
        {servicesList?.map((service) => (
          <ServiceCard key={service.title} {...service} />
        ))}
      </div>
    </section>
  );
};

export default Service;
