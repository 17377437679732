import React from "react";
import Button from "./Button";
import { ButtonSize } from "../constants/enum";

const ProjectCard = ({ title, description, image, ctas }) => {
  const openInNewWindow = (url) => () => {
    window.open(url, "_blank");
  };
  return (
    <div className="flex flex-col rounded-2xl group bg-secondary lg:h-[65vh] relative overflow-hidden shadow-custom-1">
      <img
        src={image}
        alt={title}
        className="w-full rounded-t-2xl mn-auto bg-cover bg-center"
      />

      <div className="px-4 py-6 flex flex-col justify-between  bg-secondary md:px-6 lg:z-10 lg:duration-200  lg:absolute -bottom-[168px] group-hover:lg:bottom-0  ">
        {/*168px cause that's the height of content card */}

        <div>
          <p className="text-2xl mb-2 lg:pb-2  lg:pt-1 font-bold sm:text-3xl md:text-4xl">
            {title}
          </p>
          <p className="lg:invisible group-hover:lg:visible  truncate-lines min-h-[84px]">
            {description}
          </p>
        </div>

        <div className="flex flex-col gap-4 sm:items-start pt-8  lg:flex-row lg:flex-wrap">
          {ctas?.map((cta) => (
            <Button
              size={ButtonSize.Large}
              extendClassName="!bg-black rounded-lg"
              onClick={openInNewWindow(cta.link)}
            >
              {cta.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
