import React from "react";
import { ButtonSize, ButtonVariant } from "../constants/enum";

const Button = ({
  variant = ButtonVariant.Primary,
  size = ButtonSize.Medium,
  children,
  onClick,
  extendClassName = "",
}) => {
  const buttonClasses = {
    [ButtonVariant.Primary]:
      "bg-primary text-white font-bold py-2 px-4 rounded",
    [ButtonVariant.Secondary]:
      "bg-secondary  text-gray-700 font-bold py-2 px-4 rounded",
    [ButtonVariant.Tertiary]: "bg-primary font-bold py-2 px-4 rounded",
  };

  return (
    <button
      className={`button ${buttonClasses[variant]} ${
        size === ButtonSize.Small
          ? "text-sm"
          : size === ButtonSize.Large
          ? "text-lg"
          : "text-base"
      } ${
        size === ButtonSize.Small
          ? "px-2 py-1"
          : size === ButtonSize.Large
          ? "px-6 py-3"
          : "px-3 py-1.5"
      } ${extendClassName} `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
