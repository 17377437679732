const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="p-8 flex flex-col rounded-2xl group bg-secondary  relative overflow-hidden shadow-custom-1">
      <img src={icon} alt={title} className="w-[3rem]" />

      <div className="pt-6 flex flex-col justify-between  bg-secondary   ">
        <div>
          <p className="text-2xl mb-6  font-bold sm:text-3xl md:text-4xl">
            {title}
          </p>
          <p className="text-lg truncate-4">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
