import React from "react";
import Heading from "../components/Heading";
import { projectsList } from "../constants/businessLogic";
import ProjectCard from "../components/ProjectCard";

const Work = () => {
  return (
    <section
      id="work"
      className="py-10 lg:px-10 max-w-[85rem] mx-auto scroll-m-[72px]"
    >
      <Heading
        text="Recent"
        highlightedText="Work"
        extendedClass="pb-9 lg:pb-12"
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8">
        {projectsList?.map((project) => (
          <ProjectCard {...project} />
        ))}
      </div>
    </section>
  );
};

export default Work;
