import React, { useState } from "react";
import { BrandNoBg } from "../assests/images";
import Button from "./Button";
import { CiMenuBurger } from "react-icons/ci";
import { LiaTimesSolid } from "react-icons/lia";
import { scrollToView } from "../utils";
import { mailUs } from "../constants/businessLogic";

const Navbar = () => {
  const [sideDrawer, setSideDrawer] = useState(false);

  const renderMenu = (className) => (
    <div className={className}>
      <div
        className="cursor-pointer text-md"
        onClick={scrollToView("commitment")}
      >
        About
      </div>
      <div className="cursor-pointer text-md" onClick={scrollToView("service")}>
        Services
      </div>
      <div className="cursor-pointer text-md" onClick={scrollToView("work")}>
        Work
      </div>
      <div
        className="cursor-pointer text-md"
        onClick={scrollToView("testimonial")}
      >
        Testimonials
      </div>
      <Button
        onClick={() => window.open(mailUs, "_blank")}
        extendClassName="bg-primary w-[150px] h-[48px] mr-4 rounded-xl"
      >
        Get In Touch
      </Button>
    </div>
  );

  return (
    <>
      <div className="bg-secondary shadow-custom-1 flex justify-between items-center h-[72px] fixed w-full z-50">
        <img
          className="basis-1/4 ml-6 w-[204px] h-[100px]"
          src={BrandNoBg}
          alt="brand"
        />
        {renderMenu("basis-[40%] items-center hidden lg:flex lg:gap-8")}
        {!sideDrawer && (
          <CiMenuBurger
            onClick={() => setSideDrawer((prev) => !prev)}
            className="lg:hidden mr-8 cursor-pointer"
          />
        )}
        {sideDrawer && (
          <LiaTimesSolid
            onClick={() => setSideDrawer((prev) => !prev)}
            className="mr-8 cursor-pointer"
          />
        )}
      </div>
      {sideDrawer &&
        renderMenu(
          "bg-secondary flex flex-col gap-8 justify-center items-center h-fit fixed w-full py-5 right-0 z-40 top-[72px] shadow-custom-1 sm:h-full sm:w-fit sm:top-0 sm:px-5  ease-in-out"
        )}
    </>
  );
};

export default Navbar;
