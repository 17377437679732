// not using ts that's why will object instead of ENUMS
export const ORANGE_BOUNDED_VARIANTS = {
  FILLED: "FILLED",
  FILLED_WITH_ICON: "FILLED_WITH_ICON",
  INVERSE: "INVERSE",
};

export const ButtonVariant = {
  Primary: "primary",
  Secondary: "secondary",
  Tertiary: "tertiary",
};

export const ButtonSize = {
  Small: "small",
  Medium: "medium",
  Large: "large",
};
