import React from "react";
import ParallaxText from "../components/ParallaxText";
import { scrollableQuote } from "../constants/businessLogic";

const VELOCITY = 0.3;
const ScrollingQuotes = () => {
  return (
    <section className="flex flex-col capitalize text-5xl sm:text-6xl md:text-7xl gap-6 py-12 ">
      <ParallaxText baseVelocity={VELOCITY} startOffset={-15}>
        {scrollableQuote.quote1}
      </ParallaxText>
      <ParallaxText baseVelocity={-VELOCITY} startOffset={-15}>
        {scrollableQuote.quote2}
      </ParallaxText>
    </section>
  );
};

export default ScrollingQuotes;
