import React from "react";
import Heading from "../components/Heading";
import { commitmentData, ourNumbers } from "../constants/businessLogic";

const Commitment = () => {
  return (
    <section
      id="commitment"
      className="flex flex-col-reverse gap-8 pt-20 items-center py-10 lg:px-10 max-w-[85rem] mx-auto lg:flex-row scroll-m-[72px]"
    >
      <div className="basis-1/2 relative">
        <img
          className="h-[450px] w-screen rounded-2xl inset-0 object-cover"
          src={commitmentData.imageUrl}
          alt="commitment-card"
        />
        <div class="rounded-2xl absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.35))]"></div>
        <div className="flex flex-wrap absolute gap-4 bottom-5 left-10">
          {ourNumbers.map((item, index) => (
            <div
              className={`flex flex-col justify-between rounded-2xl ${
                index === 0
                  ? "bg-black text-secondary"
                  : "bg-[linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.35))]"
              } w-[120px] sm:w-[150px] h-[160px] sm:h-[192px] px-2 py-8`}
            >
              <span className="text-[2rem] sm:text-[3rem] font-bold">
                {item.value}
              </span>
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="basis-1/2">
        <Heading
          text="Our"
          highlightedText="Commitments"
          extendedClass="pb-9 lg:pb-12"
        />
        <p className="text-xl font-normal leading-8">
          {commitmentData.description}
        </p>
      </div>
    </section>
  );
};

export default Commitment;
