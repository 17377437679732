// TODO: add FOnt-family
// structure
//create component in component folder
//in container assemble them and make a section
import Marquee from "./containers/Marquee";
import Process from "./containers/Process";
import Service from "./containers/Service";
import ScrollingQuotes from "./containers/ScrollingQuotes";
import Team from "./containers/Team";
import Work from "./containers/Work";
import Commitment from "./containers/Commitment";
import Testimonials from "./containers/Testimonials";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./containers/Hero";

function App() {
  return (
    <div className=" min-h-screen w-full bg-secondary scroll-smooth ">
      <Navbar />
      <Hero />
      <div className="px-5">
        <Commitment />
      </div>
      <ScrollingQuotes />
      <div className="px-5">
        <Service />
        <Work />
      </div>
      <Testimonials />
      <div className="px-5">
        <Team />
      </div>
      <Marquee />
      <div className="px-5">
        <Process />
      </div>
      <Footer />
    </div>
  );
}

export default App;
