import React from "react";
import { ORANGE_BOUNDED_VARIANTS } from "../constants/enum";
import { motion } from "framer-motion";

const OrangeBoundedText = ({
  variant = ORANGE_BOUNDED_VARIANTS.FILLED,
  children,
  extendedClass = "",
  icon,
}) => {
  let classname = "";
  switch (variant) {
    case ORANGE_BOUNDED_VARIANTS.FILLED:
    case ORANGE_BOUNDED_VARIANTS.FILLED_WITH_ICON:
      classname =
        "p-2 bg-accent text-white w-fit  -rotate-[3deg] hover:rotate-0";
      break;
    case ORANGE_BOUNDED_VARIANTS.INVERSE:
      classname =
        "hover:bg-accent hover:text-white hover:-rotate-[3deg] hover:p-4 transition-all";
      break;
    default:
      break;
  }
  return (
    <motion.div
      className={`rounded-2xl  delay-200 duration-300 ease-in-out font-sans ${classname} ${extendedClass}`}
    >
      {children}
      {ORANGE_BOUNDED_VARIANTS.FILLED_WITH_ICON === variant && (
        <div className="absolute left-0 bottom-0 -translate-x-1/2 translate-y-1/2">
          {icon}
        </div>
      )}
    </motion.div>
  );
};

export default OrangeBoundedText;
