import React from "react";
import { BrandNoBg } from "../assests/images";
import { mailUs, socialLinks } from "../constants/businessLogic";
import { scrollToView } from "../utils";

const Footer = () => {
  return (
    <>
      <section className="flex flex-col p-8 justify-between md:flex-row gap-4">
        <div className="flex flex-col gap-4 basis-1/4">
          <img className="w-[210px] h-[80px]" src={BrandNoBg} alt="logo" />
          <p className="font-bold text-2xl pl-5 cursor-default">
            Big Ideas? We’re here to help. Reach us at{" "}
          </p>
          <p
            onClick={() =>window.open(mailUs, "_blank")}
            className="text-accent underline font-bold text-2xl pl-5 cursor-pointer"
          >
            hello@chaev.in
          </p>
        </div>
        <div className="footer basis-1/2 flex flex-wrap justify-center items-end gap-6 md:gap-10 text-lg font-semibold ">
          {/* <div className="basis-1/2 flex font-[500] "> */}
          <div className="cursor-pointer" onClick={scrollToView("commitment")}>
            About
          </div>
          <div className="cursor-pointer" onClick={scrollToView("service")}>
            Services
          </div>
          <div className="cursor-pointer" onClick={scrollToView("work")}>
            Work
          </div>
          <div className="cursor-pointer" onClick={scrollToView("testimonial")}>
            Testimonials
          </div>
          {/* </div> */}
        </div>
        <div className="footer center basis-1/4 flex flex-wrap justify-center gap-4 col-gap-2 items-end  font-[500]">
          {socialLinks.map((item) => (
            <a
              className="rounded-3xl p-2 border border-[#E6E6E6] h-fit cursor-pointer"
              href={item.link}
              target="__blank"
            >
              {item.logo}
            </a>
          ))}
        </div>
      </section>
    </>
  );
};

export default Footer;
