import React from "react";
import OrangeBoundedText from "../components/OrangeBoundedText";
import { ORANGE_BOUNDED_VARIANTS } from "../constants/enum";
import { InteractiveMarque } from "../components/InteractiveMarquee";

const Marquee = () => {
  return (
    <div className="py-10">
      <InteractiveMarque>
        <div className="inline-flex gap-8 w-max items-center text-3xl md:text-4xl lg:text-5xl ">
          <img
            className="lg:max-h-[4.75rem] max-h-[4.25rem] md:max-h-[4.5rem] animate-spin-slow duration-500"
            src="https://cdn.prod.website-files.com/66f98e76da53b22d4ca8e887/66fb6b2688b49dfb923f488b_star.svg"
            alt="turn"
          />
          <OrangeBoundedText
            variant={ORANGE_BOUNDED_VARIANTS.INVERSE}
            extendedClass="font-bold p-4 my-0"
          >
            {" "}
            Let's Start
          </OrangeBoundedText>
        </div>
        <div className="inline-flex gap-8 w-max items-center text-3xl md:text-4xl lg:text-5xl ">
          <img
            className="lg:max-h-[4.75rem] max-h-[4rem] md:max-h-[4.5rem] animate-spin-slow duration-500"
            src="https://cdn.prod.website-files.com/66f98e76da53b22d4ca8e887/66fb6b2688b49dfb923f488b_star.svg"
            alt="turn"
          />
          <OrangeBoundedText
            variant={ORANGE_BOUNDED_VARIANTS.INVERSE}
            extendedClass="font-bold p-4 my-0"
          >
            {" "}
            Let's Start
          </OrangeBoundedText>
        </div>
        <div className="inline-flex gap-8 w-max items-center text-3xl md:text-4xl lg:text-5xl ">
          <img
            className="lg:max-h-[4.75rem] max-h-[4.25rem] md:max-h-[4.5rem] animate-spin-slow duration-500"
            src="https://cdn.prod.website-files.com/66f98e76da53b22d4ca8e887/66fb6b2688b49dfb923f488b_star.svg"
            alt="turn"
          />
          <OrangeBoundedText
            variant={ORANGE_BOUNDED_VARIANTS.INVERSE}
            extendedClass="font-bold p-4 my-0"
          >
            {" "}
            Let's Start
          </OrangeBoundedText>
        </div>
      </InteractiveMarque>
    </div>
  );
};

export default Marquee;
