import { Services1, Services2, Services3, Services4 } from "../assests/images";
import { PiInstagramLogo, PiLinkedinLogoBold } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";

export const teamMembers = [
  {
    name: "Anuj Choudhary",
    designation: "CEO",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/team%2Fanuj.png?alt=media&token=804a9c3c-d9ad-4c01-8704-edae73921de4",
  },
  {
    name: "Kavish Vohra",
    designation: "CMO",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/team%2Fkavish.png?alt=media&token=cf785b4c-cc47-4310-a10d-c9f077b68652",
  },
  {
    name: "Jatin Kumar",
    designation: "CTO",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/team%2Fjatin.png?alt=media&token=309ff3cc-8d91-4d3f-9ed3-bef0cb501808",
  },
  {
    name: "Maithili Fiske",
    designation: "UI/UX Designer",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/team%2Fmaithili.png?alt=media&token=8dac0ed9-6349-48d6-90c2-ed2cce363acb",
  },
  {
    name: "Mimansha Swarup",
    designation: "Lead Backend Developer",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/team%2Fmimansha.png?alt=media&token=838288a0-cda6-4ad2-aec6-72c874c5cf11",
  },
  {
    name: "Sagar",
    designation: "Lead Frontend Developer",
    profile:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/team%2Fsagar.png?alt=media&token=e8991c73-362a-4835-8b78-e0f195128b19",
  },
];

export const ourProcess = [
  {
    key: "1",
    label: "We talk",
    children:
      "We begin by understanding your vision and requirements through open communication, ensuring we're aligned with your goals.",
  },
  {
    key: "2",
    label: "We research",
    children:
      "Next, we conduct thorough research, analyzing market trends and exploring the best technologies to create a solution tailored to your business.",
  },
  {
    key: "3",
    label: "We create",
    children:
      "Finally, we bring your vision to life, developing high-quality, innovative solutions that meet your needs and are delivered on time.",
  },
];

export const scrollableQuote = {
  quote1: "Crafting visions into reality with innovative design.",
  quote2: " Where imagination meets innovation.",
};

export const servicesList = [
  {
    title: "Mobile App Development",
    description:
      "Specializing in Flutter development for cross-platform mobile apps, we create high-performance, scalable applications for both iOS and Android that elevate your business",
    icon: Services1,
  },
  {
    title: "Website Development",
    description:
      "Building fast, responsive, and SEO-optimized websites using React, designed to deliver a seamless user experience and drive business growth.",
    icon: Services2,
  },
  {
    title: "Backend Development",
    description:
      "Developing robust, scalable backend solutions using Node.js to power your applications with secure, high-performance server-side logic.",
    icon: Services3,
  },
  {
    title: "UI/UX Design",
    description:
      "Designing user-friendly, conversion-focused interfaces that boost user engagement and create seamless digital experiences across web and mobile platforms.",
    icon: Services4,
  },
];
export const projectsList = [
  {
    title: "Anonymate",
    description:
      "Anonymate is an anonymous social media platform for students to share thoughts, confessions, and experiences without revealing their identity. It offers relatable posts, polls, and discussions tailored to the student community. Anonymate fosters a supportive and engaging environment for students to connect.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/projects%2Fanonymate.png?alt=media&token=9669edcd-406f-40fe-ab0e-a8dbcda60cca",
    ctas: [
      {
        label: "See  on Appstore",
        link: "https://apps.apple.com/in/app/anonymate/id6575391021",
      },
      {
        label: "See on Playstore",
        link: "https://play.google.com/store/apps/details?id=com.anonymate.anonymate&hl=en_IN",
      },
    ],
  },
  {
    title: "Payzli POS",
    description:
      "Optimize your business with Payzli POS - the all-in-one solution for swift checkouts, inventory control, and powerful insights. User-friendly, secure, and perfect for beauty salons and retail stores. Simplify operations and boost customer satisfaction with Payzli!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/projects%2Fsalonpay.png?alt=media&token=8168643b-b13b-4361-9029-00741e43cdae",
    ctas: [
      {
        label: "See Live",
        link: "https://qa.pos.payzli.com/",
      },
      {
        label: "See on Playstore",
        link: "https://play.google.com/store/apps/details?id=com.payzli.pos",
      },
    ],
  },
  {
    title: "Lib Travel",
    description:
      "LIB Travel, Liberia's Ministry of Health's official app for tracking and notifications. Mandatory for all entering Liberia, the app informs MOH of symptoms for immediate assistance. Take daily temperatures, submit Covid-19 test reports, and undergo a Rapid Test at the airport. Your privacy is paramount - the app shares info only with MOH. Download to contribute to community safety and economic viability.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/projects%2Flib_travel.png?alt=media&token=16ba6f04-d83c-4fee-b39c-7dda7e70027b",
    ctas: [
      {
        label: "See on Playstore",
        link: "https://play.google.com/store/apps/details?id=com.tuma.libtravel",
      },
    ],
  },
  {
    title: "WiseRx",
    description:
      "Save big on US prescriptions! Get up to 85% off with Rx discount cards, accepted at 65,000 pharmacies nationwide for FDA-approved medications, including pet prescriptions. Start cutting healthcare costs today!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/projects%2Fwiserx.png?alt=media&token=366aa117-5d9c-4954-b0fb-f6f260d55acf",
    ctas: [
      {
        label: "See on Playstore",
        link: "https://play.google.com/store/apps/details?id=com.primotech.wiserx",
      },
    ],
  },
  {
    title: "SayNoCash",
    description:
      "Unlock unbeatable discounts with SayNoCash! Instant Tatkal room bookings, original-priced food, and seamless dining experiences. Enjoy quick orders, efficient takeaways, and home delivery. Simplify with QR code self-ordering and waiter-assisted mobile orders for accuracy. Convenience and savings, all in one!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/projects%2Fsaynocash_user.png?alt=media&token=04c492ca-3022-4420-9e07-75d038a1fefd",
    ctas: [
      {
        label: "See on Playstore",
        link: "https://play.google.com/store/apps/details?id=com.saynocash.user",
      },
    ],
  },
  {
    title: "SayNoCash Restaurant",
    description:
      "Explore SayNoCash Restaurant Partner App - your digital solution for hassle-free ordering in hotels and restaurants. Scan, select, and pay via QR code, with intelligent system assignments for efficient service. Elevate your experience with machine-learning support for customers, waiters, managers, and owners. Streamline operations effortlessly!",
    image:
      "https://firebasestorage.googleapis.com/v0/b/chaev-54a86.appspot.com/o/projects%2Fsaynocash_restaurant.png?alt=media&token=c2bb82a4-c7ff-45c6-8c8a-426af305f95e",
    ctas: [
      {
        label: "See on Playstore",
        link: "https://play.google.com/store/apps/details?id=com.saynocash.restaurant",
      },
    ],
  },
];

export const socialLinks = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/chaevservices/",
    logo: <PiInstagramLogo className="w-6 h-6" />,
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/company/chaev",
    logo: <PiLinkedinLogoBold className="w-6 h-6" />,
  },
  {
    title: "X(Twitter)",
    link: "https://x.com/chaevservices",
    logo: <RiTwitterXLine className="w-6 h-6" />,
  },
];

export const commitmentData = {
  description:
    "At Chaev, we believe innovation is sparked by blending technology with creativity. Our mission is to create tech-driven brands that not only captivate audiences but also drive progress and growth. By delivering cutting-edge solutions, we empower businesses to meet today\u2019s needs while preparing for tomorrow's opportunities. Chaev is dedicated to shaping a future where technology and brand development evolve together, unlocking new possibilities for our clients.",
  imageUrl:
    "https://cdn.prod.website-files.com/66f98e76da53b22d4ca8e887/66f98e76da53b22d4ca8e9d7_Image%203.avif",
};

export const userTestimonials = [
  "Working with Chaev has been an incredibly smooth and enriching experience. The team's calm professionalism, proactive approach, and passion for technology made the collaboration seamless. Chaev\u2019s expertise and commitment to both learning and supporting others significantly contributed to the success of our project. I am grateful to have worked with such a dedicated and knowledgeable company.",

  "I highly recommend Chaev for their exceptional skills in Flutter development. Having had the pleasure of collaborating with them on a project, I can confidently say that their team demonstrates dedication, proficiency, and innovative problem-solving abilities. Chaev consistently delivers high-quality work, showcasing a deep understanding of development principles. Their collaborative nature and positive attitude make them a valuable asset to any team. If you're looking for a talented team to lead your project, Chaev is the one to consider.",

  "It is rare to find a company so fluent in multiple technologies and languages. Chaev's problem-solving abilities are impressive; whenever faced with an issue, they always have a solution. Their team is eager to learn new things, and anyone working with them will undoubtedly gain a wealth of knowledge.",

  "Chaev\u2019s innovative problem-solving skills are truly remarkable. They excel at translating complex business requirements into seamless, dynamic mobile applications. Their creativity and technical expertise make them a valuable partner for any project.",

  "Chaev stands out as one of the best teams I\u2019ve worked with. Their problem-solving skills are fantastic, and their coding practices are so clear that even newcomers can easily understand the structure. They are always appreciative of others' efforts and consistently go the extra mile to support their clients, both technically and personally, with kindness and professionalism.",

  "Working with Chaev has been an absolute pleasure. Their expertise in app development, combined with their dedication and professionalism, ensured that our project not only met but exceeded our expectations. Chaev\u2019s attention to detail and commitment to delivering high-quality work on time make them an invaluable partner. I highly recommend Chaev for any development project and look forward to future collaborations.",

  "Don\u2019t choose Chaev if you\u2019re okay with average work or delays. If you want basic solutions or poor communication, they\u2019re not the right team. Chaev always delivers high-quality results on time, and they push for the best with their innovation and dedication. They\u2019re not for anyone who wants to stay behind.",
];

export const ourNumbers = [
  {
    title: "Years of Expertise",
    value: "5+",
  },
  {
    title: "Happy Businesses",
    value: "7+",
  },
  {
    title: "Satisfaction Goal",
    value: "100%",
  },
];

const subject = "Project Collaboration with Chaev";
const body = `Hi Chaev Team,

I\u2019m interested in discussing a project named [Project Name].

Tech Stack Preference: [Your Preferred Tech Stack].

Please let me know a good time to connect.

Best regards,
[Your Name]`;

export const mailUs = `mailto:hello@chaev.in?subject=${encodeURIComponent(
  subject
)}&body=${encodeURIComponent(body)}`;
