import React from "react";

const TeamCard = ({ name, designation, profile }) => {
  return (
    <div className="flex group relative  flex-col-reverse rounded-2xl bg-white shadow-custom-1 sm:flex-row sm:justify-between sm:items-center sm:px-9 sm:py-5 md:py-6 lg:p-0 hover:lg:text-white transition duration-300 ease-in-out ">
      <div className="flex z-10 overflow-hidden relative flex-col p-4 sm:p-0 lg:w-full lg:flex-row lg:items-center lg:justify-between lg:rounded-2xl lg:px-9 lg:py-6">
        <div class="lg:absolute hidden lg:block inset-0 bg-white transition-transform duration-300 ease-out transform group-hover:-translate-y-full" />
        <div class=" static lg:absolute inset-0 hidden lg:block bg-accent transition-transform duration-300 ease-out transform translate-y-full group-hover:translate-y-0" />
        <p className="m-0 text-3xl font-bold z-10">{name}</p>
        <p className="m-0 z-10">{designation}</p>
      </div>

      <img
        src={profile}
        alt={name}
        className="w-full sm:w-[8rem] rounded-t-2xl sm:rounded-2xl sm:-rotate-3 lg:absolute lg:hidden group-hover:lg:block group-hover:lg:right-[15%] lg:w-[16rem] lg:rotate-[4deg] lg:z-30"
      />
    </div>
  );
};

export default TeamCard;
