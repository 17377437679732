import React from "react";
import Heading from "../components/Heading";
import { userTestimonials } from "../constants/businessLogic";
import { PiQuotesFill } from "react-icons/pi";
// import { motion } from "framer-motion";
import { InteractiveMarque } from "../components/InteractiveMarquee";

// const marqueeVariants = {
//     animate: {
//       x: [0, -6900], // Adjust based on the total width of the content
//       transition: {
//         x: {
//           repeat: Infinity,
//           repeatType: "loop",
//           duration: 250,
//         },
//       },
//     },
//   };

const Testimonials = () => {
  return (
    <section id="testimonial" className="pb-8 scroll-m-[72px]">
      <div className="pt-10 px-5 lg:px-10 max-w-[85rem] mx-auto">
        <Heading
          text="Our Clients"
          highlightedText="Love Us"
          extendedClass="pb-9 lg:pb-12"
        />
      </div>
      <InteractiveMarque speed={0.5}>
        <div className="flex items-start gap-8 pb-8">
          {userTestimonials.map((item) => (
            <div className="bg-secondary shadow-custom-1 rounded-2xl p-8  w-[500px] text-lg font-normal flex gap-4">
              <img
                src="https://cdn.prod.website-files.com/66f98e76da53b22d4ca8e887/66fb6a8bb6d0739456f8529b_5.svg"
                alt="quote"
                className="w-[100px] h-[28px]"
              />
              <p>{item}</p>
            </div>
          ))}
        </div>
      </InteractiveMarque>
    </section>
  );
};

export default Testimonials;
