import React from "react";
import OrangeBoundedText from "./OrangeBoundedText";
import { ORANGE_BOUNDED_VARIANTS } from "../constants/enum";

const ArrowImage = () => {
  return (
    <img
      style={{ filter: "drop-shadow(5px 6px #00000029)" }}
      className="w-[31px] drop-shadow-md"
      src="https://cdn.prod.website-files.com/66f98e76da53b22d4ca8e887/66f98e76da53b22d4ca8e99e_Arrow.svg"
      alt="arrow"
    />
  );
};
const Heading = ({
  text,
  highlightedText,
  highlightVariant = ORANGE_BOUNDED_VARIANTS.FILLED,
  extendedClass = "",
  tailingText = "",
}) => {
  return (
    <div
      className={`flex flex-col font-bold text-3xl sm:text-4xl md:text-5xl ${extendedClass}`}
    >
      {text}
      <OrangeBoundedText variant={highlightVariant} icon={<ArrowImage />}>
        {highlightedText}
      </OrangeBoundedText>
      {tailingText}
    </div>
  );
};

export default Heading;
